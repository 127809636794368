import React, { PureComponent } from 'react';
import {
  SEO,
  LevelHero,
  Inspiration,
  LevelChallenge,
  LevelBenefits,
  LevelObjective
} from 'components';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';
import star from 'images/Levels/star.svg';
import level9Icon from 'images/Shared/LevelIcons/WhiteStroke/level_09_icon.svg';

class Level9 extends PureComponent {
  state = {
    componentHasMounted: false
  };

  componentDidMount() {
    this.setState({
      componentHasMounted: true
    });
  }

  render() {
    const { data, viewportWidth, user, game, selectedLevel } = this.props;
    const { levelData, cogs } = data;
    const {
      benefits,
      challenge,
      duration,
      levelPageSummary,
      levelVideoCode,
      objective,
      scoring,
      title,
      subTitle
    } = levelData.childMarkdownRemark.frontmatter;
    let { tools } = levelData.childMarkdownRemark.frontmatter;
    const { componentHasMounted } = this.state;
    if (game && game.uniqueDocs) {
      game.uniqueDocs.forEach((uniqueDocs) => {
        if (uniqueDocs.level === selectedLevel) {
          tools = {
            list: tools.list,
            link: uniqueDocs.link
          };
        }
      });
    }
    return user ? (
      <Wrapper>
        <SEO pageSpecificTitle="Level 9" pageSpecificDescription={levelPageSummary} />
        <LevelHero
          level={9}
          title={title}
          subTitle={subTitle}
          levelPageSummary={levelPageSummary}
          levelIcon={level9Icon}
          levelVideoCode={levelVideoCode}
        />
        <LevelObjective objective={objective} />
        <LevelChallenge
          challenge={challenge}
          duration={duration}
          tools={tools}
          reduceToolsFontSize
        />
        <Overview>
          <p
            style={{
              fontSize: '1.5rem',
              lineHeight: '1.333em',
              maxWidth: '926px'
            }}>
            Do you start with a bang and support that over the term?
            <br />
            Or do you test various approaches first and scale up into to a larger presence?
            <br />
            Can you leverage any existing assets, activities across the company/industry to extend
            the value of our budget?
            <br />
            Finally remember you have competitors that you must assume are planning something
            similar so every minute counts!
            <br />
            Are there any campaigns you encountered that could inspire your plan? How did they do
            it, what media were used?
          </p>
          <br />
          <br />
          <div>
            <GreenBox>1.</GreenBox>
            <p>
              <span>Reading Phase</span> — Review all the materials completed and provided to date.
              <span>Allow 2 hours</span>
            </p>
          </div>
          <div>
            <GreenBox>2.</GreenBox>
            <p>
              <span>Ideation Workshop 1</span> — Set a date for an online team workshop to begin the
              ideation session. Review the planner and budget and insure all team members understand
              the terms. Consider the different approaches to a launch strategy as above. Let the
              ideas come. You may need to split the team post workshop to research different aspects
              of the various ideas and media chosen.
              <span>
                Allow 1.5 hours per the workshop and 2 hours to draft and finalise the outputs
              </span>
            </p>
          </div>
          <div>
            <GreenBox>3.</GreenBox>
            <p>
              <span>Ideation Workshop 2</span> — Resume ideation session and gather all ideas using
              the planner concept. Check budgets and update the launch plan.
              <br />
              <span>
                Allow 1.5 hours per the Workshop and 2 hours to draft and finalise the outputs
              </span>
            </p>
          </div>
          <div>
            <GreenBox>4.</GreenBox>
            <p>
              <span /><b>Revise and complete your campaign planner</b> (worth referencing your strategy work
              level 6 here) <b>and create the final presentation of the launch plan</b>.
              <br />
              <span>Allow 1 hour to discuss options</span>
              <span>Allow 3 hours to draft the planner</span>
            </p>
          </div>
          <div>
            <LightGreenBox>
              <img src={star} alt="star" />
            </LightGreenBox>
            <p>
              <span>Note</span> — This will be your last team challenge submission for the campaign,<br />make it count!
            </p>
          </div>
        </Overview>
        <InnerWrapper>
          <LevelBenefits
            benefits={benefits}
            level={selectedLevel}
            objective={objective}
            viewportWidth={viewportWidth}
            scoring={scoring}
          />
        </InnerWrapper>
        <Inspiration
          img={cogs}
          author="Eisenhower"
          quote="Plans are nothing, Planning is everything"
          quoteLargeFontSize="3.525rem"
          maxWidth="36.875em"
          textWrapperTop="6em"
          viewportWidth={viewportWidth}
        />
      </Wrapper>
    ) : (
      componentHasMounted && <Redirect to="/" noThrow />
    );
  }
}

const Wrapper = styled.section``;

const InnerWrapper = styled.div`
  max-width: 90em;
  margin: 0 9.1%;
`;

const Overview = styled.div`
  background-color: rgba(239, 237, 228, 0.3);
  padding: 0 9.027777777777777% 2.625rem;
  display: flex;
  flex-direction: column;

  > div {
    align-items: flex-start;
    display: inline-flex;
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 2.75em;

    > p {
      margin-left: 0.833em;
      position: relative;
      top: 0.325em;
      font-weight: 400;
      line-height: 1.333em;
      max-width: 874px;

      > span {
        font-weight: 700;
      }

      > span:nth-of-type(2) {
        margin-top: 1em;
      }

      > span:not(:first-of-type) {
        display: block;
        color: var(--cx-dark-green);
      }

      > div {
        align-items: flex-start;
        display: inline-flex;
        width: 100%;

        p {
          margin-left: 0.833em;

          span {
            font-weight: 700;
          }
        }
      }
    }

    ul {
      margin-top: 0.75em;
      line-height: 1.333em;

      li {
        display: flex;
        margin-bottom: 0.75em;

        span {
          margin-right: 1.5em;
        }
      }
    }
  }
`;

const GreenBox = styled.span`
  align-items: center;
  background-color: var(--cx-dark-green);
  border-radius: 0.125em;
  bottom: 0.02em;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: 600;
  height: 1.917em;
  justify-content: center;
  position: relative;
  width: 1.917em;
`;

const BeigeBox = styled.span`
  align-items: center;
  background-color: var(--cx-dark-beige);
  border-radius: 0.125em;
  bottom: 0.02em;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: 600;
  height: 1.917em;
  justify-content: center;
  position: relative;
  width: 1.917em;
`;

const LightGreenBox = styled.span`
  align-items: center;
  background-color: var(--cx-light-green);
  border-radius: 0.125em;
  bottom: 0.02em;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: 600;
  height: 1.917em;
  justify-content: center;
  position: relative;
  width: 1.917em;
`;

export const Level9Query = graphql`
  {
    levelData: file(sourceInstanceName: { eq: "levelData" }, relativePath: { eq: "level_09.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          subTitle
          levelPageSummary
          levelVideoCode
          challenge {
            heading
            detail
          }
          objective
          benefits {
            forPlayers
            forTheCampaign
          }
          duration {
            time
            detail
          }
          scoring {
            challenge {
              points
              detail
            }
            teamGame {
              points
              detail
            }
          }
          tools {
            list
            link
          }
        }
      }
    }
    cogs: file(relativePath: { eq: "Shared/Inspiration/cogs.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 590, maxHeight: 620) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default Level9;
